//nb d'item max par ligne pour les galleries
$maxgalitem:8 !default;


//une grille d'item, parfait pour afficher une gallerie.

@mixin flexgal-container($gutter:null, $base:true, $nest:true, $fallbackie9: $fallbackie9){
	//pour une grille responsive, on evite de déclarer plusieurs fois le display:flex etc...
	@if ($base==true) {
		display: flex;
		flex-wrap: wrap;
	}

	//si la grille a des gouttières, il faut une marge négative du conteneur.
	//Mais attention, il si le conteneur n'est pas lui même délimité par un parent, les marges négatives vont créer des scrollbars horizontales (car cela va sortir du body).
	//avec la variable nest (par defaut active), on permet la suppression de ses marges négatives.

	@if($gutter!=null) {
		@if($nest==true) {
			margin-left:($gutter) / -2;
			margin-right:($gutter) / -2;

		}



	}

}
@mixin flexgal-item($col) {
  	flex-basis: (100%/$col);
  	max-width: (100%/$col);
		&.double {
			flex-basis: (100%/$col)*2;
			max-width: (100%/$col)*2;
		}

	//fallback IE9 (nécéssite modernizr pour ajouter la classe no-flexbox)
	@if ($fallbackie9==true) {
		.no-flexbox & {
			display: inline-block;
			vertical-align: top;
		}
	}
}

@mixin flexgal($col:null, $gutter:null, $base:true, $nest:true) {
	@each $breakpoint, $gutter in $grid-col-gutter {
		@include flexgal-container($gutter, $base, $nest);
	}
	> * {
		@include flexgal-item($col, $gutter);

	}

}


//EXEMPLE :
//.profils-grid {
//  pour mobile, une grille flexbox de 2 items par ligne avec un gutter de 10px
//	@include flex-grid-container(10px);
//	li {
//		@include flex-grid-item(2, 10px);
//	}
//  pour le breakpoint "sm", une grills de 3 items par ligne avec un gutter de 20px
//	@include breakpoint(sm) {
//  l'argument false evite de répéter des regles css inutiles (display:flex...)
//		@include flex-grid-container(20px, false);
//		li {
//			@include flex-grid-item(3, 20px);
//
//		}
//	}
//
//}


//mixin qui cré les classes .flexgal-$breakpoint-$nbitem
//pour chaque breakpoint, jusqu'à 8 items par ligne par défaut ($maxgalitem:8)
@mixin make-flexgal(){
	[class*="flexgal-"] {
		@each $breakpoint, $gutter in $grid-col-gutter {
			@include breakpoint($breakpoint) {
				@include flexgal-container($gutter);
			}
		}
		@each $breakpoint in $breakpoints {
				@include breakpoint($breakpoint) {
					@for $i from 2 through $maxgalitem {
						.flexgal-#{nth($breakpoint, 1)}-#{$i} > * {
								@include flexgal-item($i);
						}
					}
			}
		}
		> * {
			flex: 1 0 100%;
			@each $breakpoint, $gutter in $grid-col-gutter {
					@include breakpoint($breakpoint) {
						padding-left:$gutter/2;
						padding-right:$gutter/2;
					}
				}
		}
	}

}
