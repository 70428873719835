$input-color: inherit !default;
$input-font-family: inherit !default;
$input-font-size: inherit !default;
$input-background: #fff !default;
$input-background-focus: #fff !default;
$input-border: 1px solid #cacaca !default;
$input-border-focus: 1px solid #8a8a8a !default;
$input-height:40px !default;
$form-padding:8px !default;
$form-spacing:1rem !default;
/// Box shadow inside text inputs when not focused.
$input-shadow: inset 0 1px 2px rgba(#000, 0.1) !default;
/// Box shadow outside text inputs when focused.
$input-shadow-focus: 0 0 5px #cacaca !default;
$input-radius: $global-radius !default;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out !default;


$text-input-selectors:'input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"]';

@mixin form-element {
	display: block;
 	box-sizing: border-box;
	width: 100%;
	height:$input-height;
	padding: $form-padding;
	margin:0 0 $form-spacing;
	color: $input-color;
	font-family:$input-font-family;
	font-size: $input-font-size;
	border: $input-border;
	background-color: $input-background;
	box-shadow:$input-shadow;
	border-radius: $input-radius;
	@if has-value($input-transition) {
    	transition: $input-transition;
  	}
	// Focus state
	&:focus {
		border:$input-border-focus;
		background-color: $input-background-focus;
		outline:none;
		box-shadow: $input-shadow-focus;
		@if has-value($input-transition) {
      		transition: $input-transition;
    	}
	}

	&.input--mini {
		max-width: 60px;
	}
	&.inline {
		display: inline-block;
	}



}
@mixin generate-text-inputs() {
#{$text-input-selectors},
textarea, select {
	@include form-element;
	-webkit-appearance: none;
    -moz-appearance: none;
}

// Reset styles on button-like inputs
  [type='submit'],
  [type='button'] {
    border-radius: $global-radius;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

 // Reset Normalize setting content-box to search elements
  input[type='search'] {
    box-sizing: border-box;
  }
}
