//inspiré de flexbox grid : http://flexboxgrid.com/#top +

$grid-total-col:12 !default;
$grid-col-gutter: (
  xs: 10px,
  sm: 20px,
  md: 30px,

) !default;


//Largeure maximale du site. Par défaut 1200px.
$site-maxwidth:1200px !default;
//La grille fonctionne avec des marges négatives, il faut donc un container avec un padding au moins égal à la marge négative afin d'éviter des scrollbars horizontales.
//On défini donc les paddings du container pour chaque médiaqueries.
$container-padd: (
	xs:10px,
	md:15px,
) !default;

$container-maxwidth: (

	sm: 1000px,
	md: 1170px
) !default;

$fallbackie9:false !default;

@mixin flexrow(){

  		display: flex;
 		  flex: 0 1 auto;
  		flex-flow:row wrap;

			@each $breakpoint, $gutter in $grid-col-gutter {
				@include breakpoint($breakpoint) {
						margin-right: ($gutter / -2);
						margin-left: ($gutter / -2);

				}

			}



			//suppression des espaces blancs des inline-block
			@if($fallbackie9) {
				.no-flexbox & {
					letter-spacing: -0.31em;
				}
			}


}

@mixin flexcolbase($gutter:null){
	flex: 1 0 100%;
	max-width: 100%;

	@each $breakpoint, $gutter in $grid-col-gutter {

				padding-left: ($gutter) / 2;
       	padding-right: ($gutter) / 2;

		}

	//fallback ie9
	@if($fallbackie9) {
		.no-flexbox & {
			display: inline-block;
			vertical-align: top;
			letter-spacing: normal;
		}

	}



}


@mixin flexcol($cols:null){

	@if($cols!=null) {
		flex-basis: (100%/$grid-total-col)*$cols;
		max-width: (100%/$grid-total-col)*$cols;



	}



	@if($fallbackie9) {
		.no-flexbox & {
		width:(100%/$grid-total-col)*$cols;
		}
	}
}
@mixin flexgrid(){
	@each $breakpoint in $breakpoints {
		@include breakpoint($breakpoint) {
			@for $i from 1 through $grid-col-count {
				.col-#{nth($breakpoint, 1)}-#{$i} {

				}

			//colonne auto

			}
		}
	}
}

//@include flexgrid();
@mixin generate-flexgrid() {

  //BASE des Différents éléments, à ne pas répéter.
    .row {
      //row base
      display: flex;
      flex: 0 1 auto;
      flex-flow:row wrap;
      @if($fallbackie9) {
				.no-flexbox & {
					letter-spacing: -0.31em;
				}
			}
      @each $breakpoint, $gutter in $grid-col-gutter {
        @include breakpoint($breakpoint) {
          margin-right: ($gutter / -2);
          margin-left: ($gutter / -2);





          }

        }

  //collapse
  //Grille sans padding
  &.collapse {
    margin-left: 0;
    margin-right: 0;
    > .col, [class^="col-"] {
      padding-left: 0;
      padding-right: 0;

    }
  }
}

.col, [class^="col-"] {
  flex: 1 0 100%;
  max-width: 100%;
  //fallback ie9
  @if($fallbackie9) {
    .no-flexbox & {
      display: inline-block;
      vertical-align: top;
      letter-spacing: normal;
    }

  }
  @each $breakpoint, $gutter in $grid-col-gutter {
    @include breakpoint($breakpoint) {
				padding-left: ($gutter) / 2;
       	padding-right: ($gutter) / 2;
      }
		}

}





@each $breakpoint in $breakpoints {
		@include breakpoint($breakpoint) {
			@for $i from 1 through $grid-total-col {
				.col-#{nth($breakpoint, 1)}-#{$i} {
					@include flexcol($i)

				}
			}
      .col-#{nth($breakpoint, 1)}-20p {
        flex-basis:20%;
        max-width: 20%;
      }
      .col-#{nth($breakpoint, 1)}-30p {
        flex-basis:30%;
        max-width: 30%;
      }
      .col-#{nth($breakpoint, 1)}-40p {
        flex-basis:40%;
        max-width: 40%;
      }
      .col-#{nth($breakpoint, 1)}-60p {
        flex-basis:60%;
        max-width: 60%;
      }
      .col-#{nth($breakpoint, 1)}-80p {
        flex-basis:80%;
        max-width: 80%;
      }

      //alignement
      .center-#{nth($breakpoint, 1)} {
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }

      .end-#{nth($breakpoint, 1)} {
        justify-content:flex-end;
        text-align: right;
      }
      .start-#{nth($breakpoint, 1)} {
        justify-content:flex-start;
        text-align:left;
      }
      .between-#{nth($breakpoint, 1)} {
        justify-content: space-between;
      }
      .around-#{nth($breakpoint, 1)} {
        justify-content: space-around;
      }
      //alignement vertical
      .middle-#{nth($breakpoint, 1)} {
        align-items: center;
      }



      //Col sizes
      .shrink-#{nth($breakpoint, 1)} {
        flex: 1 1 0%;
      }
      .col-auto-#{nth($breakpoint, 1)} {
        flex: 1 1 auto;
        max-width: none;
      }
      .col-double-#{nth($breakpoint, 1)} {
        flex: 2 0 0%;
        max-width: none;
      }

		}
	}

  .valign-bottom {
    align-self: flex-end;
  }

.container {
//	max-width: $site-maxwidth;
	width: 100%;
	@each $breakpoint, $maxwidth in $container-maxwidth {
		@include breakpoint($breakpoint) {
			max-width: $maxwidth;
		}

	}
	margin: 0 auto;
	@each $breakpoint, $padd in $container-padd {
			@include breakpoint($breakpoint) {
				padding-left: $padd;
       	padding-right: $padd;
			}
		}
}

//width helpers




}
