$header-font-family: $body-font-family !default;
$header-font-weight: bold !default;
$header-font-style: normal !default;
$lead-size:1.2em !default;
$header-sizes: (
  xs: (
    'h1': 24,
    'h2': 20,
    'h3': 19,
    'h4': 18,
    'h5': 17,
    'h6': 16,
  ),
  sm: (
    'h1': 48,
    'h2': 40,
    'h3': 31,
    'h4': 25,
    'h5': 20,
    'h6': 16,
  ),
) !default;

$header-color: inherit !default;
$header-lineheight: 1.2 !default;
$header-margin-bottom: 0.5rem !default;
$header-text-rendering: optimizeLegibility !default;
$header-text-transform: normal !default;
$small-font-size: 80% !default;
$paragraph-lineheight: 1.5 !default;
$paragraph-margin-bottom: 1rem !default;
$paragraph-text-rendering: optimizeLegibility !default;
$link-color: $primary-color !default;
$link-color-hover: scale-color($link-color, $lightness: -14%) !default;
$link-text-decoration: none !default;
$link-text-decoration-hover: none !default;
$list-lineheight: $paragraph-lineheight !default;
$list-margin-bottom: $paragraph-margin-bottom !default;
$list-style-type: disc !default;
$list-style-position: outside !default;
$list-side-margin: 1.25rem !default;
$list-nested-side-margin: 1.25rem !default;


@mixin typo-base {
  // Typography resets
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  p,
  blockquote,
  th,
  td {
    margin: 0;
    padding: 0;
  }

  // Paragraphs
  p {
    font-size: inherit;
    line-height: $paragraph-lineheight;
    margin-bottom: $paragraph-margin-bottom;
    text-rendering: $paragraph-text-rendering;
  }

  // Emphasized text
  em,
  i {
    font-style: italic;
    line-height: inherit;
  }

  // Strong text
  strong,
  b {
    font-weight: bold;
    line-height: inherit;
  }

  // Small text
  small {
    font-size: $small-font-size;
    line-height: inherit;
  }

  // Headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    font-style: $header-font-style;
    color: $header-color;
    text-rendering: $header-text-rendering;
    margin-top: 0;
    margin-bottom: $header-margin-bottom;
    line-height: $header-lineheight;
	text-transform: $header-text-transform;

  }

  // Heading sizes
  @each $size, $headers in $header-sizes {
    @include breakpoint($size) {
      @each $header, $font-size in $headers {
        #{$header} {
          font-size: rem-calc($font-size);
        }
      }
    }
  }

  // Links
  a {
    color: $link-color;
    text-decoration: $link-text-decoration;
    line-height: inherit;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $link-color-hover;
      @if $link-text-decoration-hover != $link-text-decoration {
        text-decoration: $link-text-decoration-hover;
      }
    }

    img {
      border: 0;
    }
  }

  // Lists
  ul,
  ol,
  dl {
    line-height: $list-lineheight;
    margin-bottom: $list-margin-bottom;
    list-style-position: $list-style-position;
  }

  // List items
  li {
    font-size: inherit;
  }

  // Unordered lists
  ul {
    list-style-type: $list-style-type;
    margin-left: $list-side-margin;
  }

  // Ordered lists
  ol {
    margin-left: $list-side-margin;
  }

  // Nested unordered/ordered lists
  ul, ol {
    & & {
      margin-left: $list-nested-side-margin;
      margin-bottom: 0;
    }
  }

  p.lead {
    font-size: $lead-size;
  }
}
