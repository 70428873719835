@mixin helpers () {
.t-center {
  text-align: center;
}
.t-small {
  font-size: 14px;
}
@include breakpoint(md) {
.md-align-left {
  float: left;
  margin: 0 1em 1em 0;

}
}

}


@mixin hide-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
%hide-text {
  @include hide-text;
}
