$panel-padd:1em;
$panel-padd-xs:1em;
.panel-title {
  @extend %linotte;
}
.panel {
  p {
    @extend %marron;
  }
}
.panel-content {
  @include clearfix();
}

@include breakpoint(xs down) {
  .panel {
    background-color: #fff;
    margin-right: -10px;
    margin-left: -10px;
  }
  .panel-header {
      background: #f4d228;
  }
  .panel-title {
    color: #FFF;
    padding: $panel-padd-xs;
    letter-spacing: 0.1em;
  }
  .panel-content {
    padding: $panel-padd-xs;
  }
}


//PANEL DESKTOP ET TABLETTE
@include breakpoint(sm) {
.panel {
  padding: $panel-padd;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(#0e968f, .3);
  margin-bottom: 1em;
  background-color: #fff;
}
.panel-header {
  background: #fff244;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: linear-gradient(#fff244, $secondary-color);
  margin: $panel-padd*-1 $panel-padd*-1 0;
  padding:.5em  1em;

}
.panel-title {
  color: #0e968f;
  font-size: 13px;
  text-transform: uppercase;

  margin: 0;
}
.panel-content {
  padding: 1em;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;


}
}
.panel-question {
  @extend %linotte;
  display: flex;
  align-items: center;
  color: #f6a500;
  font-size: 20px;
  &:before {
    min-width: 34px;
    margin-right: .5em;
    content: "";
    width: 34px;
    height: 34px;
    display: inline-block;
    background: url("../img/question.png") no-repeat 0 0;
  }
}

@include breakpoint(md) {
  .panel-content {
    max-width: none;
  }
  .panel--with-pyramide {
    position: relative;
    margin-top: 120px;
    overflow: visible;
    &:before {
      z-index: 2;
      position: absolute;
      content: "";
      width: 378px;
      height: 157px;
      background: url("../img/panel-top.png");
      top: -157px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
