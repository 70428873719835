@mixin generate-checkbox() { 
[type='file'],
 [type='checkbox'],
 [type='radio'] {
    margin: 0 0 $form-spacing;
 }

// Styles for input/label siblings
  [type='checkbox'] + label,
  [type='radio'] + label {
    display: inline-block;
    margin-left: $form-spacing * 0.5;
    margin-right: $form-spacing;
    margin-bottom: 0;
    vertical-align: baseline;
  }
}