//insipré de : https://codepen.io/ndeniche/pen/ljbDL

@mixin closebtn($size:80px, $bg: #eee, $color: #000) {

  position: relative;
  display: inline-block;
  width: $size;
  height: $size;
  overflow: hidden;
  background:$bg;
  border-radius:50%;

  &:hover {
    &::before, &::after {

    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 70%;
    top: 50%;
    left: 50%;

    background: $color;
    border-radius:5px;

  }
  &::before {
    transform:translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform:translate(-50%, -50%) rotate(-45deg);
  }
}


$arrow-width:15px;
$arrow-size:50px;
$test: ($arrow-width+$arrow-size);
@mixin arrow-line-bottom() {
	position: relative;
	width: ($arrow-size*2);
	height: ($arrow-size*2);
	background-color: #000;
	&:before, &:after {
		content: "";
		background-color: #fff;
		height: $arrow-width;
		width: $arrow-size;
		position: absolute;
		top: 50%;
		left: 50%;
		border-radius: $arrow-size/7;
		margin-left: -($arrow-size/2);
		margin-top: -($arrow-width/2);


	}
	&:before {
		background-color: red;
		transform: translate3d($test*0.25, 0, 0) rotate(-45deg) ;

	}
	&:after {
		transform: translate3d(-$test*0.25 , 0, 0) rotate(45deg) ;
		//top: 5px;

	}
}
