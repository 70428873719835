@mixin visibility() {
.hide-for-xs {
  display: none !important;
  @include breakpoint(sm) {
    display: block !important;
  }
}
.hide-for-sm {
  @include breakpoint(sm) {
    display: none !important;
  }
    @include breakpoint(md) {
      display: block !important;
    }
}
.hide-for-md {
  @include breakpoint(md) {
    display: none !important;
  }
}

.show-for-md {
  display: none !important;
  @include breakpoint(md) {
    display: block !important;
  }

}
}
.hide-text {
  clip: rect(1px, 1px, 1px, 1px);
     position: absolute !important;
     height: 1px;
     width: 1px;
     overflow: hidden;
}

@include breakpoint(sm) {
.hide-text-sm {
    clip: rect(1px, 1px, 1px, 1px);
   position: absolute !important;
   height: 1px;
   width: 1px;
   overflow: hidden;
  }
}
@include breakpoint(md) {
.hide-text-md {
    clip: rect(1px, 1px, 1px, 1px);
   position: absolute !important;
   height: 1px;
   width: 1px;
   overflow: hidden;
  }
}
