$select-background: #fff !default;
$select-triangle-color: #333 !default;
$select-radius: $global-radius !default;
@mixin generate-select() {
	select {
		height: $input-height;
		padding: $form-padding;
		border: $input-border;
		margin: 0 0 $form-spacing;
		font-size: $input-font-size;
		font-family: $input-font-family;
		line-height: normal;
		color: $input-color;
		background-color: $select-background;
		border-radius: $select-radius;
		-webkit-appearance: none;
		-moz-appearance: none;
		@if $select-triangle-color !=transparent {
			@include background-triangle($select-triangle-color);
			background-size: 9px 6px;
			background-position: right ($form-spacing / 2) center;
			background-repeat: no-repeat;
		}
		// Hide the dropdown arrow shown in newer IE versions
		&::-ms-expand {
			display: none;
		}
		&[multiple] {
			height: auto;
		}
	}
}