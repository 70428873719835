// 1. Couleurs
// ----------------------
$marron:#672100;
$jaune: #f6a500;
$bleu: #35c4d2;
$grey: #7d7d7b;
$grey-light: #cbcbcb;

$primary-color: $bleu;
$secondary-color: $jaune;


// 2. Style global & TYPO
// ----------------------


$global-font-size:15px;
$body-font-family: "linotte", arial, sans-serif;
$header-font-family: "horseback", serif;
$header-sizes: (
  xs: (
    'h1': 24,
    'h2': 20,
    'h3': 19,
    'h4': 18,
    'h5': 17,
    'h6': 16,
  ),
  sm: (
    'h1': 34,
    'h2': 32,
    'h3': 26,
    'h4': 16,
    'h5': 16,
    'h6': 14,
  ),
) !default;

$header-font-weight:normal;
$header-text-transform:uppercase;
$header-color:$marron;
$body-font-weight: normal;
$body-lineheight: 1.5;
$body-font-color: $grey;
$body-background: #79e3e8;
$body-antialiased: true;
$global-radius:8px;

$lead-size:1.2em;

//boutons
$btn-bg-color: $primary-color;
$btn-bg-hover: #fff;
$btn-gradient: linear-gradient(#fded42, #f6b710);
$btn-gradient-hover: linear-gradient(#7ae3e9, #65bec2);
$btn-text-color:#672100;
$btn-text-color-hover:#067d79;
$btn-radius: 50em;
$btn-padding:.5em 2em;
$btn-transition: none;
$btn-text-transform:uppercase;



// 2. Breakpoints
// --------------

$breakpoints: (
  xs: 0,
  sm: 640px,
  md: 1024px,
);

// 3. GRID
// -------
$grid-col-gutter: (
  xs: 10px,
  sm: 20px,
);

$container-padd: (

	xs:10px,
  sm:20px,
	md:15px,
);

$container-maxwidth: (
 md:1030px,

);

$site-maxwidth:1310px;
$fallbackie9:true;


// 4. form
// --------------
$input-color: $body-font-color;
$input-font-family: inherit;
$input-font-size: inherit;
$input-background: #fff;
$input-background-focus: #fff;
$input-border: 1px solid #cacaca;
$input-border-focus: 1px solid #8a8a8a;
$input-height:40px;
$form-padding:8px;
$form-spacing:1rem;
/// Box shadow inside text inputs when not focused.
$input-shadow: inset 0 1px 10px rgba(#000, 0.2);
/// Box shadow outside text inputs when focused.
$input-shadow-focus: inset 0 1px 3px rgba(#000, 0.2);
$input-radius: 0;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;

// 5. Modules
// --------------
//modal
$modal-opacity:.95;
$modal-bg: $primary-color;
