@import 'text';
@import 'select';
@import 'checkbox';

@mixin generate-nice-form() {

	@include generate-select();
	@include generate-text-inputs();
	@include generate-checkbox();
	label {
		display: inline-block;
	}

	input[type="radio"] {margin-right: .2em  ;}

	.form-group {
		margin-bottom: 1em;
	}
}
