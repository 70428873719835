$btn-bg-color: $primary-color !default;
$btn-bg-hover: darken($primary-color, 10%) !default;
$btn-text-color:#fff ! default;
$btn-text-color-hover:#fff !default;
$btn-radius: $global-radius !default;
$btn-padding: 1em 2em !default;
$btn-transition: background, .3s !default;
$btn-text-transform:uppercase !default;
$btn-letter-spacing:0.15em !default;
$btn-gradient:false !default;
$btn-gradient-hover:false !default;

@mixin btn() {
.btn{
  background-color: $btn-bg-color;
  @if $btn-gradient {
    background: $btn-gradient;
  }
  border: none;
  border-radius: $btn-radius;
  color: $btn-text-color;
  cursor: pointer;
  display: inline-block;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  user-select: none;
  width: auto;
  padding: $btn-padding;
  transition: $btn-transition;
  text-transform: $btn-text-transform;
  letter-spacing:$btn-letter-spacing;

  &:link
  {
    color: $btn-text-color;
    text-decoration: none;
  }
  

  &:focus,
  &:hover {
    background-color: $btn-bg-hover;
    color: $btn-text-color-hover;
    @if $btn-gradient-hover {
      background: $btn-gradient-hover;
    }
  }

  &:focus{
  //  outline: .1rem dotted #ccc;
  }

  &:active{
    background-color: $btn-bg-color;
  }
  &.btn--block {
    width: 100%;
    max-width: none;
  }
}



[type=button],
[type=submit],
button{
  @extend .btn;
}

}
