// TABS SUR MOBILE
$tab-padding: 1em;
@include breakpoint(sm down) {
  .moncompte {

  }
  .tabs {
    position: fixed;
    top: $menu-top-pos;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    background-color: #f4d227;
  }
  .tabs-nav {
    //on n'affiche pas cette navigation sur mobile
    display: none;

  }
  .tabs-content {

      list-style: none;
      margin: 0;
      padding: 0;


  }
  .tab {
    display: none;
    &.accordion-selected {
      display: block;
    }
  }
  //TITRES DES ONGLETS :
  .tab-accordion-title {
    background-color: #f4d227;
    a {
      padding: $tab-padding;
      @extend %menu-item-mobile;
      @extend .chevron-down;
    }
  }
  .tab-content {
    padding: $tab-padding;
    background-color: #fff;
  }
}



//tabs sur tablettes / desktop
@include breakpoint(md) {
.tab-accordion-title {
  display: none;
}
.tabs {
  position: relative;
  box-shadow: 0 0 10px rgba(#000, .2);
  max-width: 520px;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(#000, .2);
}
.tabs-content {
  padding: 2em 0;
  > .tab {
    display: none;
    padding: 1em;
    &.selected {
      display: block;
      animation:fade-in 0.5s;
    }
  }
}
.tabs-nav {
  position: relative;
  &:after {
    position: absolute;
    content:"";
    left: 0;
    right: 0;
    background:linear-gradient(rgba(#000, .2), rgba(#000, 0));
    height: 10px;
  }
  ul {
    margin: 0;
    list-style: none;
    padding: 0;

  }
  li {

    display: inline-block;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      top: 1em;
      bottom: 1em;
      right: 0;
      width: 1px;
      background-color:#eee;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }

  }
  a {
    display: block;
    padding: 1.5em;
    text-transform: uppercase;
    color: #7e7e7c;
    opacity: .5;
  }
  //onglet actif
  li.selected {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      bottom: -8px;
      background: none;
      left: 50%;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #fff;
      z-index: 2;

    }
     a {
       opacity: 1;
     }
  }

}
}

//animation tabs
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.tabs-logout {
  position: absolute;
  right: 1em;
  top: 18px;
  z-index: 2;
  .btn {
    font-size: 12px;
  }
}
