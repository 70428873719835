$modal-bg: #FFF !default;
$modal-opacity:.9 !default;

@mixin modal() {
  /*POPUP INSCRIPTION*/

/*modal*/
body.modal-is-visible {
	overflow: hidden;
}
.modal {
    background: rgba($modal-bg, $modal-opacity);
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
	  opacity: 0;
    transform: scale(0.9);
  	transition: transform 0.2s, opacity 0.2s, visibility 0.2s;

	 overflow: hidden;
	.modal-is-visible & {
		opacity: 1;
		transform: scale(1);
		visibility: visible;
	}
}
.modal-content {
	  position: relative;
    height: 100vh;
    overflow-y: auto;
    padding: 3em 5% 4em;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.3s 0s, transform 0.3s 0s;
    background-color: #fff;
  	.modal-is-visible & {
  		    opacity: 1;
              transform: translateY(0);
  		    transition: opacity 0.3s 0.3s, transform 0.3s 0.3s;
  	}
    &.with-header {
      height: calc(100% - 130px);
    }
}

.modal-close {
	position: absolute;
    z-index: 1;
    top: 20px;
    right: 5%;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: rgba(#fff, 0.6);
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    transform: translateZ(0);
    backface-visibility: hidden;
    visibility: hidden;
    opacity: 0;
    transform: scaleZ(0);
    transition: transform 0.3s 0s, visibility 0s 0.3s, opacity 0.3s 0s;
	&::before, &::after {
		content: '';
		position: absolute;
		height: 2px;
		width: 80%;
		top: 50%;
		left:50%;
		margin-top: -1px;
		background: #000;

	}
	&:before {
		transform: translateX(-50%) rotate(45deg);


	}
	&:after {
		transform:  translateX(-50%) rotate(-45deg);
	}

	&:hover {
		background-color: #fff;
	}

	.modal-is-visible & {
		visibility: visible;
		opacity: 1;
		transition: transform 0.3s 0s, visibility 0s 0s, opacity 0.3s 0s;
		transform: scaleZ(1);
	}
}
.modal-header {
  padding: 1em 0;
      height: 130px;
  img {
    max-width: 240px;
  }
}

//modal steps
.modal-step {
  max-width: 320px;
  margin: 0 auto;
  display: none;
  &.step-is-active {
    display: block;
  }
}

//modal content
.maya-features {
  @extend %marron;
}

}
