/*----
inspiration : http://tympanus.net/Blueprints/MultiLevelMenu/
---*/

/*--- VAR ---*/


@mixin menu() {

@include breakpoint(sm down) {
  body.menu--open {
    overflow: hidden;
  }
  .nav-header {
    background-color: #000;
    position: fixed;
    z-index: 1000;
    top: 0;
      //cacher la scrollbar
      //width: calc(100% + 50px);
    right: 0;
    left: 0;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.3s;
    //font-weight: bold;
    padding: 2em 0;
    .menu--open & {
      transform: translate3d(0, 0, 0);
    }
  }
  .menu-header {
    list-style: none;
    margin: 0;
    padding: 0;
    a {
      display: block;
      padding: 1em 2.5em 1em 1.5em;
      display: block;
    }
  }
}


}
