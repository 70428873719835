

@charset 'utf-8';
// Global variables and styles
@import 'config';
@import 'util/unit';
@import 'util/breakpoint';
@import 'util/mixins';
@import 'util/helpers.scss';
@import 'reset';
@import 'grid/flexgrid2';
@import 'grid/flexgal';
@import 'typo/typo';
@import 'form/form';
@import 'modules/visibility.scss';
@import 'modules/dropdown';
@import 'modules/modal';
@import 'modules/hamburger';
@import 'modules/menu';
@import 'modules/btn';

@include reset;
@include helpers;
@include typo;
@include generate-flexgrid;
@include make-flexgal();
@include generate-nice-form;
@include hamburger;
@include menu;
@include btn;
@include visibility;
@include modal;

@font-face {
    font-family: 'horseback';
    src: url('../FONTS/horsebackslab-bold-webfont.woff2') format('woff2'),
         url('../FONTS/horsebackslab-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'abeezee';
    src: url('../FONTS/abeezee-regular-webfont.woff2') format('woff2'),
         url('../FONTS/abeezee-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'abeezee';
    src: url('../FONTS/abeezee-italic-webfont.woff2') format('woff2'),
         url('../FONTS/abeezee-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'linotte';
    src: url('../FONTS/linotte-semibold-webfont.woff2') format('woff2'),
         url('../FONTS/linotte-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
%abeezee {
  font-family: 'abeezee', sans-serif;
}
%horseback {
  font-family: 'horseback', sans-serif;
}
%linotte {
  font-family: 'linotte', sans-serif;
}
.abeezee {
  @extend %abeezee;
}
%muluc {
  color: #76c5ea;
}
.muluc {
  @extend %muluc;
}
%marron {
  color: $marron;
}
.marron {
  @extend %marron;
}
.t-size-xl {
  font-size: 2em;
}
.t-size-xxl {
  font-size: 3em;
}
//project vars :
$menu-top-pos:210px;


img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}
//STYLES
@include breakpoint(sm down) {
  %menu-item-mobile {
    text-transform: uppercase;
    color: #fff;
    display: block;
    border-bottom: 1px solid #f3be19;
    padding: .5em 0;
    letter-spacing: $btn-letter-spacing;
  }
}

@import 'modules/closebtn';
@import 'modules/flags/flag-icon';
@import 'modules/chevron';
@import 'modules/tabs';
@import 'modules/panel';
.close {
  @include closebtn(33px, #fff, #79e3e8);
}
.chevron-down {
  @extend %chevron;
  @extend %chevron-down;
}





//sticky footer
.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site-content {
  flex: 1 0 auto;
  padding: 1em 0;
}
.menu-trigger {
  background: url("../img/ribbon.png") no-repeat 0 0;
  width: 60px;
  height: 69px;
  display: block;
  position: absolute;
  top: 0;
  z-index: 100;
  //invisible sur desktop
  @include breakpoint(md) {
    display: none;
  }
}

body {
  background-image: url("../img/bg.jpg");
  background-repeat: repeat-y;
  background-position: top center;
}

.site-header {
    border-top:15px solid #f4d227;
}
.logo-header {
  max-width: 366px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  position: relative;
  text-align: center;
  // HALO uniquement sur desktop
  @include breakpoint(md) {
  margin-top: 30px;
    &:after {
      position: absolute;
      content: "";
      background: url("../img/halo.png");
      width: 265px;
      height: 264px;
      left: -100px;
      bottom: -90px;
      opacity: .5;
      user-select: none;
      z-index: -1;
    }
  }
}


.site-footer {
  background: url("../img/footer.png") repeat-x bottom;
  background-size:cover;
  min-height: 221px;
  @include breakpoint(md) {
    background-size:auto;
    min-height: 260px;
    padding-top: 220px;
  }
}
//MENU POUR MOBILE UNIQUEMENT
@include breakpoint(sm down) {



.site-nav {
  background-color: #f4d227;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: $menu-top-pos;
  padding: 1em 2em;
  overflow-y: auto;
  overflow-x: hidden;
  //par défaut la navigation mobile est invisible et sort de l'écran par le bas
  transform: translateY(100%);
  opacity: 0;
  transition: all, .3s;
  //lorsqu'on clique l'icone menu, la navigation apparait du bas vers sa position initiale
  .menu-is-active & {
    transform: translateY(0);
    opacity: 1;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
    }
    a{
      @extend %menu-item-mobile;
    }
  }
  .facebook-links {
    display: none;
  }


  //compte
  .menu-compte {
    margin: 1em 0;
    .btn {
      margin-bottom: .5em;
    }
  }

  //boutons site-nav mobile
  //les boutons dans la zone jaune sont différents sur mobile
  .btn {
    background: #eebf17;
    font-size: 13px;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }


}

//LOGIN MOBILE
.login-box {
  position: absolute;
  background-color: #f4d227;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 2em;
  z-index: 2;
  //par défaut il est invisible et sort du canva
  transform: translateX(100%);
  opacity: 0;
  transition: all, .3s;

  .login-box-is-visible &{
    opacity: 1;
    transform: translateX(0);
  }
  .mdp-oublie {
    margin-top: -10px;
    color: #fff;
    text-align: right;
    a {
      color: #FFF;
    }
  }
  label {
    text-align: left;
    color: #fff;
    display: block;
  }
  .btn {
    padding: 1em 2em;
  }

}
//liens facebook en position fixe sur mobile
.menu-facebook {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 1em;
  opacity: 1;
  display: block;

}
}

//langue
.langue-switch {
  ul {
    opacity: 0;
    visibility: hidden;
    height: 0;

  }
  //menu langue ouvert
  &.expanded {
    ul {
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }
}


//menu tablette & desktop
@include breakpoint(md) {

  //supprime les scrollbars horizontal du menu du header positionné en absolute
  body {
       overflow-x: hidden;
   }
  //le menu langue et login se retrouve en haut du site
  .nav-header-md {

    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 1000px;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row-reverse;
  }
  .login-box {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    padding: 2em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 10px rgba(#000, .3);
    //par défaut la box est invisible et caché au top du canva
    opacity: 0;
    transform:translateY(-100% );
    transform:translateY(calc(-100% - 10px));
    transition: all, .3s;
    z-index: -1;

    .login-box-is-visible & {
      opacity: 1;
      transform: translateY(0);
      z-index: 2;

    }
  }
.site-nav {

}

//langue-switch DESKTOP
.langue-switch {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  background: url("../img/ribbon.png") no-repeat bottom;
  width: 59px;
  padding:1.5em .5em 20px .5em;
  position: relative;
  margin-right: 2em;
  top: -15px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 20px;
    background: url("../img/ribbon-bg.png") repeat-y;
    z-index: -1;
  }
  a {

  }
  .country-name {
    display: none;
  }
  .flag-icon {

  }
}
.menu-footer {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0 1em;
    text-transform: uppercase;
    font-size: 13px;
  }
  a {
    color: #fff;
  }
}
.menu-facebook {
  display: none;
}

//menu compte desktop
.menu-compte {
  padding-top: 2em;
  .btn {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    background:#35c3d1;
    color: #fff;
    &:hover {
      background: #fff;
      color: #35c3d1;
    }
  }
}
}

// FORM prophetie
//sur la home :
.home-form {
  max-width: 320px;
}
.form-prophetie {

margin: 0 auto;
  label {
    font-size: 13px;
    color: #0e968f;
    text-transform: uppercase;
  }
  .label-radio {
    font-size: 15px;

  }

  input[type="text"],
  input[type="number"],
  select {
    @extend %horseback;
    color: #0e968f;
    background-color: #3cc8d7;
    border: none;
    text-align: center;
  }

}
.home-form-txt {
  color: #fff;
  text-align: center;
}


%maya-shadow {
  box-shadow: 0 0 15px rgba(#0e968f, 1);

}
.btn--small {
  font-size: 13px;
  padding: .5em 1em;
}
.btn--invert {
  background:#35c3d1;
  color: #fff;
  &:hover {
    background: #fff;
    color: #35c3d1;
  }
}

.btn--maya {

  padding: 1em 1.2em;
  @extend %horseback;
  color: #672100;
  background-color: #fff144;
  background-image: linear-gradient(#fff144, #f6a500);
  border: 4px solid #d78d2d;
  letter-spacing: normal;
  position: relative;
  font-size: 1.2em;
  line-height: 1.2;
  color: #672100;
  border-radius: 10px;
  max-width: 200px;
  width: 100%;

  &:hover {
    color: #672100;
    //@extend %maya-shadow;
    background-image: linear-gradient(#f6a500, #fff144);

  }

  &:focus {
    outline: none;
    transform: scale(1);
  }

  &.with-deco {
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width:28px;
      height: 39px;
      background: url("../img/btn-maya-ornament.svg");
      background-size: 28px 39px;
      transform: translateY(-50%);
    }
    &:before {
      left: -28px;
    }
    &:after {
      right: -28px;
      transform:scaleX(-1)  translateY(-50%);
    }
  }

  &.btn--small {
    @extend .btn--small;
    //width: auto;
    border-width: 3px;
  }
}

//bouton maya homepage
.home-form .btn--maya  {
  &:hover {
    filter: drop-shadow(0 0 5px rgba(#0e968f, 0.5));
    background-color: transparent;
    //transform: scale(1.02);
  }
}

//bouton reseaux sociaux
.btn--form {
  text-transform: none;
  color: #fff;
  border-radius: 4px;
  letter-spacing: normal;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 16px;
}
.btn--social {
  text-transform: none;
  color: #fff;
  border-radius: 4px;
  letter-spacing: normal;
  padding: .5em .5em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:link, &:visited {
    color: #fff;
  }
  &:hover {
    color: #fff;
  }

  i {
    margin: 0 4px 0 0;
    font-size: 2em;
  }

  &.btn--small {
    i {
      font-size: 16px;
    }
  }
}
.btn--facebook {
  background: linear-gradient(#5677c4, #4563ab);
  &:hover {
    background: #4563ab;
  }
}
.btn--google {
  background: linear-gradient(lighten(#d34836, 10%), #d34836);
  &:hover {
    background: #d34836;
  }
}
.btn--tw {
  background: #1e9fe3;
  &:hover {
    background: #048fd4;
  }
}
.btn--email {
  background: #0d968e;
  &:hover {
    background: #12877f;
    color: #FFF;
  }
}
.btn-with-price {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &.breakline {
    flex-direction: column;
  }
}
.btn-price {
  margin-left: .5em;
  font-size: 2em;
}
//BOUTON LIRE LA SUITE DE LA PAGE RESULTATS
.more-link {
  padding: .5em 1em;
  width: auto;
  font-size: 14px;
}
.more-link-xs {
  .btn {
    max-width: 150px;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}


//page compte
.propheties-liste {
  .prophetie-animal-img {
    max-width: 80px;
    margin-bottom: 0;
  }
  .prophetie {
    padding: 1em;
    background: linear-gradient(#f4f4f4, rgba(#fff, 0));
    .btn--maya {
      width: auto;
    }
  }

}

/*---------PAGE RESULTATS--------*/
/*popup*/
.maya-features {
  padding: 0 2em;
  p.lead {
    margin-bottom: .2em;
  }
  ul {
    list-style-type: none;
    margin: 0 0 1em;
    padding: 0;

  }
  li {
    position: relative;
    padding-left: 30px;
    line-height: 1.8;
    img {
    top: 50%;
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    }
  }
}

//BREADCRUMB
.breadcrumb {
  list-style: none;
  margin: 0 0 2em;
  text-align: center;
  padding: 0;

  li {
    opacity: .5;
    text-transform: uppercase;
    letter-spacing: normal;
    font-size: 12px;
    display: inline-block;
    margin-right: .5em;
    &:last-child {
      margin-right: 0;
    }
    i {
      margin-left: .5em;
    }
    //active step
    &.active {
      opacity: 1;
    }
  }
}

p.septxt {
  margin: .3em 0;
  position: relative;
  text-align: center;
  span {
    display: inline-block;
    padding: .5em;
    background-color: #fff;
    z-index: 2;
    position: relative;
  }
  &:after {
    content: "";
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    background-color: $grey-light;
    top: 50%;
    transform: translateY(-50%);
  }
}
@include breakpoint(md){
.more-link-text {
  display: flex;
  text-align: justify;
  .more-link {
    margin-left: 2em;
    align-self: flex-end;
    flex: 1 0 auto;
  }
}
}


// FICHES SIGNES / ANIMAUX / TRECANS
%croco {
  color: #7c8833;
}
%croco-light {
  color: #aeba11;
}
%muluc {
  color: #68a2cf;
}
%muluc-light {
  color: #77c5e9;
}
.fiche {
  h4, h5 {
    line-height: 1;
    margin-bottom: 0.2em;
  }
  h4 {
    font-size: 30px;
    margin-bottom: 0;
  }
  h5 {
    font-size: 17px;

  }

}
.fiche--croco {

  h4, p {
      @extend %croco;
  }
  h5 {
      @extend %croco-light;
  }
}
.fiche--muluc {
  h4, p {
    @extend %muluc;
  }
  h5 {
    @extend %muluc-light;
  }
}
