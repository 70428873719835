@mixin clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
  }
}

/// Adds a downward-facing triangle as a background image to an element. The image is formatted as an SVG, making it easy to change the color. Because Internet Explorer doesn't support encoded SVGs as background images, a PNG fallback is also included.

@mixin background-triangle($color: #000) {
  $rgb: 'rgb%28#{red($color)}, #{green($color)}, #{blue($color)}%29';

  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: #{$rgb}"></polygon></svg>');

}

@mixin triangle($size, $color, $direction: 'up') {
	width: 0;
	height: 0;

	@if $direction == 'up' { 
		border-right: $size solid transparent;
		border-bottom: $size * 1.732 solid $color; 
		border-left: $size solid transparent; 
	} @else if $direction == 'down' {
		border-top: $size * 1.732 solid $color; 
		border-right: $size solid transparent; 
		border-left: $size solid transparent; 
	} @else if $direction == 'left' {
		border-top: $size solid transparent; 
		border-right: $size * 1.732 solid $color; 
		border-bottom: $size solid transparent; 
	} @else if $direction == 'right' {
		border-top: $size solid transparent; 
		border-bottom: $size solid transparent; 
		border-left: $size * 1.732 solid $color; 
	}
}



@mixin closebtn($size:80px, $bg: #eee, $color: #000) {
	
  position: relative;
  display: inline-block;
  width: $size;
  height: $size;
  overflow: hidden;
  background:$bg;
  border-radius:50%;
  
  &:hover {
    &::before, &::after {
      
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 80%;
    top: 50%;
    left: 50%;
    
    background: $color;
    border-radius:5px;
    
  }
  &::before {
    transform:translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform:translate(-50%, -50%) rotate(-45deg);
  }
}

@mixin centerflex() {
	 display: flex;
   align-items: center;
   justify-content: center;
}