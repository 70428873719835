$chevron-bd-w:3px !default;
$chevron-size:8px !default;
%chevron {

    &:after {
      border-style: solid;
    	border-width: $chevron-bd-w $chevron-bd-w 0 0;
    	content: '';
    	display: inline-block;
    	height: $chevron-size;
    	position: relative;
    	transform: rotate(-45deg);
      margin-left: .2em;
    	vertical-align: middle;
    	width: $chevron-size;
  }
}
%chevron-down {
  &:after {
	transform: translateY(-50%) rotate(135deg);
  }
}
%chevron-right {
  &:after {
  transform: rotate(45deg);
  }
}
%chevron-left {
  &:after {
    transform: rotate(-135deg);
  }
}
